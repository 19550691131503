// @import "reset";
// @import "variables";

*{
	font-family: 'Roboto Slab', serif;
	font-weight: 300;
  font-size: 18px;
}

h1 {
  display: block;
  font-size: 48px;
	letter-spacing: .04em;
  margin-block-start: 0.1em;
  margin-block-end: 1em;
}

h2{
	font-size: 48px;
	margin-bottom: 0px;
	letter-spacing: .04em;
}

h4{
	font-weight: 700;
	letter-spacing: .03em;
}

h3{
	font-size: 1.3em;
	margin-top: 50px;
}

li{
 margin: 0.5em 0;
}

.black-link{
	color: black;
}

.blue-link:link{
	color: #81BEF7;
}

.blue-link:visited{
	color: #BCA9F5;
}

.subtitle{
	margin-top: 0px;
	margin-bottom: 0px;
	font-weight: 300;
	letter-spacing: .04em;
}

.project{
	display: inline-block;
	border: #eee 1px solid;
	padding: 10px;
	margin-right: 20px;
	margin-top: 20px;
	max-width: 200px;

/*	border-radius: 8px;*/
	vertical-align: top;
}

.project img{
	max-width: 200px;
	max-height: 150px;

	display: block;
	margin: 0 auto;
}

.project .description{
	opacity: 0;
	position:absolute;
	bottom: 0px;
/*	background-color: rgba(0,0,0,0.7);
	color:white;*/
	background-color: white;
	color: black;

	padding: 8px;
	margin-right: 10px;
	height: 190px;
	vertical-align: middle;

	font-size: .95em;
	transition: all .5s ease;
}

.project:hover .description{
	opacity: 0.85;
}


hr{
	border-top: 1px solid #eee;
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
}

.post-list a {
  text-decoration: none;
}

p {
margin: 0 0 1.2rem;
}

.post-list {
  margin-top: 50px;
  width: 70%;
}

.post-snippet .snippet{
    display:block;
    margin-bottom:10px;
    padding: 10px 10px 10px 0;
}

.post-snippet .p-title{
    color:#000;
    font-size:1rem;
    font-weight:700;
    margin-bottom:0;
    padding-right:1rem
}

.post-snippet .p-subtitle{
    color:#555;
}

.post-snippet .p-date{
    color:#999;
    font-size:.8rem
}

.snippet:hover{
    background:#fcf5f5;
    color:#000;
    text-decoration:none
}

.post img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

// Settled on moving the import of syntax highlighting to the bottom of the CSS
// ... Otherwise it really bloats up the top of the CSS file and makes it difficult to find the start
@import "highlights";
